import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { ManagedEtaConfiguration } from './launch-darkly';

const defaultManagedEtaConfiguration: ManagedEtaConfiguration = {
  managedEtaBounds: {
    ceiling: { low: 30, high: 75 },
  },
  default: true,
};

const defaultFlags: LDFlagSet = {
  groupOrderActions: false,
  createABatch: false,
  recoverOrder: false,
  reassignOrder: false,
  markOrderUndeliverable: false,
  manualBatching: false,
  spotlightLink: '',
  developerOnlyFeatures: false,
  stagingScreen: false,
  testOrders: false,
  notifications: false,
  cancelOrder: false,
  driverAlerts: false,
  assignToDriver: false,
  failover: false,
  erroredOrder: false,
  etaAnalytics: false,
  associateDrivers: false,
  manualCatering: false,
  enableManualEtaMode: false,
  managedEtaConfiguration: defaultManagedEtaConfiguration,
};

export default defaultFlags;
